import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentDeild } from '../../actions/currentDeild';
import Hashids from 'hashids';

import './GamesSlider.css';

const GamesSlider = () => {

    var hashids = new Hashids('illugi geitin', 6);

    const states = useSelector(state => state);
    const dispatch = useDispatch();

    const date = new Date();

    const getFilteredGames = (games) => {
        let tempArr = [];
        for(let i = 0; i < games.length; i++) {
            if(new Date(games[i].LeikDagur) >= date) {
                tempArr.push(games[i]);
                if(tempArr.length >= 12) {
                    break;
                }
            }
        }
        return tempArr;
    }

    const fetchStubbData = async (externalReference) => {
        /*const data = await fetch('https://stubbur.app/webservice/ksiGame/'+externalReference,{
            mode: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(res => res.json())
        .then(
            (result) => {
                const eid = hashids.encode(result.id);
                window.open('https://stubb.is/events/'+eid);
            },
            (error) => {
                console.log(error);
            }
        )*/
        
    }

    //console.log(states);

    const visibleGames = useMemo(() => {
        if(states.games && Object.keys(states.games).length != 0 && Object.getPrototypeOf(states.games) == Object.prototype) {
            return getFilteredGames(states.games.ArrayMotLeikir.MotLeikur);
        } else {
            return [];
        }
    }, [states.games]);

    const formatStrToDate = (str) => {
        var days = ['Sun','Mán','Þri','Mið','Fim','Fös','Lau'];
        var months = ['jan','feb','mar','apr','maí','jún','júl', 'ágú', 'sep', 'okt', 'nóv', 'des'];
        var date = new Date(str);
        var day = days[date.getDay()];
        var month = months[date.getMonth()];
        var time = '';

        if(date.getMinutes() == '0') {
            time = date.getHours() + ":" + date.getMinutes() + '0';
        } else {
            time = date.getHours() + ":" + date.getMinutes();
        }

        return [day + " " + (date.getDate()) + ". " + month, time];
    }

    const handler = (value) => {
        if(value == "Karla") {
            dispatch(currentDeild("bestadeild_karla.php"));
        } else if(value == "Kvenna") {
            dispatch(currentDeild("bestadeild_kvenna.php"));
        } else if(value == "Kvenna_nedri") {
            dispatch(currentDeild("bestadeild_kvenna_nedri.php"));
        } else if(value == "Karla_nedri") {
            dispatch(currentDeild("bestadeild_karla_nedri.php"));
        } else if(value == "Karla_efri") {
            dispatch(currentDeild("bestadeild_karla_efri.php"));
        } else if(value == "Kvenna_efri") {
            dispatch(currentDeild("bestadeild_kvenna_efri.php"));
        }
    }


    return(
        <div className="games-slider-container">
            <div style={{display: 'flex', alignSelf: 'start', alignItems: 'center', marginTop: '-15px', width: '100%'}}>
                <h2 style={{color: 'white', paddingLeft: '20px'}}>{"Næstu Leikir"}</h2>
                <div style={{marginLeft: '10px', display: 'flex', paddingBottom: '20px', paddingTop: '20px', paddingRight: '10px',  overflow: 'auto'}}>
                    <div className={states.deild == 'bestadeild_karla.php' ? "pill-active" : "pill"} style={{marginRight: '10px', fontSize: '12px'}} onClick={() => {
                        handler('Karla');
                    }}>Karla</div>
                    <div className={states.deild == 'bestadeild_kvenna.php' ? "pill-active" : "pill"} style={{marginRight: '10px', fontSize: '12px'}} onClick={() => {
                        handler('Kvenna');
                    }}>Kvenna</div>
                    <div className={states.deild == 'bestadeild_karla_efri.php' ? "pill-active" : "pill"} style={{marginRight: '10px', fontSize: '12px'}} onClick={() => {
                        handler('Karla_efri');
                    }}>Karla efri</div>
                    <div className={states.deild == 'bestadeild_kvenna_efri.php' ? "pill-active" : "pill"} style={{marginRight: '10px', fontSize: '12px'}} onClick={() => {
                        handler('Kvenna_efri');
                    }}>Kvenna efri</div>
                    <div className={states.deild == 'bestadeild_karla_nedri.php' ? "pill-active" : "pill"} style={{marginRight: '10px', fontSize: '12px'}} onClick={() => {
                        handler('Karla_nedri');
                    }}>Karla neðri</div>
                    <div className={states.deild == 'bestadeild_kvenna_nedri.php' ? "pill-active" : "pill"} style={{fontSize: '12px'}} onClick={() => {
                        handler('Kvenna_nedri');
                    }}>Kvenna neðri</div>
                </div>
            </div>
            <div className="games-slider">
                {visibleGames.map((game, i) => {
                    let gameTime = formatStrToDate(game.LeikDagur);
                    return(
                        <div className="slider-game-container">
                            <div className="slider-game">
                                <p style={{textAlign: 'right', marginTop: '0', marginBottom: '0'}}>{game.UmferdNumer + '. umferð'}</p>
                                <div className="slider-game-info">
                                    <div className="slider-game-teams">
                                        <div style={{marginBottom: '-10px'}} className="slider-game-team">
                                            <img className="slider-logo" src={"https://bestadeildin.is/teamlogos/" + game.FelagHeimaNumer + ".png"}/>
                                            <p className="slider-team-name">{game.FelagHeimaNafn}</p>
                                        </div>
                                        <div className="slider-game-team">
                                            <img className="slider-logo" src={"https://bestadeildin.is/teamlogos/" + game.FelagUtiNumer + ".png"}/>
                                            <p className="slider-team-name">{game.FelagUtiNafn}</p>
                                        </div>
                                    </div>
                                    <div className="slider-game-date">
                                        <p className="slider-time">{gameTime[1]}</p>
                                        <p className="slider-date">{gameTime[0]}</p>
                                    </div>
                                </div>
                                <div className="slider-game-tickets">
                                    <button className="slider-tickets-button" onClick={() => {
                                        //fetchStubbData(game.LeikurNumer);
                                        window.open('https://stubb.is/eventlink/'+game.LeikurNumer);
                                    }}>Miðar</button>
                                </div>
                            </div>
                            {i == visibleGames.length -1 ? 
                                <div></div>
                            :
                                <div className="slider-game-separator"></div>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default GamesSlider